import { roles } from "config";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import StorageService from "services/storage.services";
import { checkValidPermission } from "utilities/utils";
// all view components here
import {
  AddCatalog,
  Catalog,
  CatalogList,
  Home,
  ProjectDetail,
  SearchView,
  BlogList,
  AddBlog,
  BlogDetails,
  MyBlogs,
  AddDomain,
} from "views";

export const DEFAULT_LAYOUT_PATH = "/dashboard";

export const CoreLayoutComponents = [
  {
    path: "/home",
    exact: true,
    component: Home,
  },
];
export const DefaultLayoutComponents = [
  {
    path: `${DEFAULT_LAYOUT_PATH}/catalogs`,
    exact: true,
    component: Catalog,
  },

  {
    path: `${DEFAULT_LAYOUT_PATH}/details/:id`,
    exact: true,
    component: ProjectDetail,
  },
  {
    path: `${DEFAULT_LAYOUT_PATH}/addCatalog`,
    exact: true,
    isAuthRequired: true,
    permit: [roles.admin, roles.owner],
    component: AddCatalog,
  },
  {
    path: `${DEFAULT_LAYOUT_PATH}/catalog-list/:type/:searchValue?`,
    exact: true,
    component: CatalogList,
  },
  {
    path: `${DEFAULT_LAYOUT_PATH}/catalogCatagory/:techId`,
    exact: true,
    component: CatalogList,
  },
  {
    path: `${DEFAULT_LAYOUT_PATH}/search/:tag`,
    exact: true,
    component: SearchView,
  },
  {
    path: `${DEFAULT_LAYOUT_PATH}/blog-list/:type?`,
    exact: true,
    component: BlogList,
  },
  {
    path: `${DEFAULT_LAYOUT_PATH}/blog/:blogId`,
    exact: true,
    component: BlogDetails,
  },
  {
    path: `${DEFAULT_LAYOUT_PATH}/add-blog`,
    exact: true,
    component: AddBlog,
    isAuthRequired: true,
  },
  {
    path: `${DEFAULT_LAYOUT_PATH}/my-blogs`,
    exact: true,
    component: MyBlogs,
    isAuthRequired: true,
  },
  {
    path: `${DEFAULT_LAYOUT_PATH}/:forApproval/:blogId`,
    exact: true,
    component: BlogDetails,
    isAuthRequired: true,
    permit: [roles.admin, roles.owner],
  },
  {
    path: `${DEFAULT_LAYOUT_PATH}/domain`,
    exact: true,
    isAuthRequired: true,
    permit: [roles.admin, roles.owner],
    component: AddDomain,
  },
];

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={function (props) {
      if (StorageService.getUserData()) {
        if (rest.permit) {
          const isValidUser = checkValidPermission(rest.permit);
          if (isValidUser) {
            return <Component {...props} />;
          } else {
            return (
              <Redirect
                to={{ pathname: "/", state: { from: props.location } }}
              />
            );
          }
        }
        return <Component {...props} />;
      } else {
        return (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        );
      }
    }}
  />
);

const SetRoutes = (route) => {
  const globalRoute = (
    <Route
      path={route.path}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
  const privateRoute = (
    <PrivateRoute
      path={route.path}
      permit={route.permit}
      component={route.component}
    />
  );
  if (route.isAuthRequired) {
    return privateRoute;
  } else {
    return globalRoute;
  }
};

export default SetRoutes;
